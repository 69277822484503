import React, { useState, useEffect } from "react";
import { Chart as ChartJS, BarElement } from "chart.js";
import "../../css/StatusChart.css";
import { Bar } from "react-chartjs-2";
import { NacionalidadesPorBase } from "./RequisicaoAPI/Counts";
import { Card, Table, Row, Col } from "react-bootstrap";

ChartJS.register(BarElement);

const InternoNacChart = () => {
  const [estado, setEstado] = useState("");
  const [time, setTime] = useState(90000);
  const bases = [
    "AC",
    "AL",
    "AM",
    "AP",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MG",
    "MJ",
    "MS",
    "MT",
    "PA",
    "PB",
    "PE",
    "PI",
    "PR",
    "RJ",
    "RN",
    "RO",
    "RR",
    "RS",
    "SC",
    "SE",
    "SP",
    "TO",
  ];

  const result = NacionalidadesPorBase(estado, time);

  var data = {
    labels: result.map((x) => x.label),
    datasets: [
      {
        label: [""],
        data: result.map((x) => x.data),
        backgroundColor: result.map((x) => x.backgroundColor),
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      // labels: {
      //   fontSize: 70,
      // },
    },
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
    },
  };

  return (
    <>
      <Row>
        <Col>
          <Card style={{ height: 78, padding: 10 }} align="center">
            <Row align="center">
              <Col>
                <strong>Selecione a base: </strong>
                <div>
                  <select
                    style={{ height: 30, borderRadius: 5, marginTop: 5 }}
                    onChange={(event) => setEstado(event.target.value)}
                  >
                    <option value="">Brasil</option>
                    {bases.map((estado) => (
                      <option value={estado}>{estado}</option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col>
                <strong>
                  Selecione a taxa de atualização do gráfico e tabela:{" "}
                </strong>
                <div>
                  <select
                    style={{ width: 50, height: 30, borderRadius: 5 }}
                    onChange={(event) => setTime(event.target.value)}
                  >
                    <option value={90000}>90</option>
                    <option value={60000}>60</option>
                    <option value={30000}>30</option>
                  </select>
                  <strong> segundos</strong>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div>
        <div align="center">
          <h3 className="titulo">
            {estado != ""
              ? " GRÁFICO DE ESTATÍSTICAS DE INTERNOS POR NACIONALIDADE NO " +
                estado
              : " GRÁFICO DE ESTATÍSTICAS DE INTERNOS POR NACIONALIDADE NO BRASIL "}{" "}
          </h3>
        </div>
        {/* <div class="scrollbar scrollbar-primary">
          <div class="force-overflow">
            <button class="btn btn-primary">teste</button>            
          </div>
        </div> */}
        <div class="chartWrapper">
          <div class="chartAreaWrapper">
            <Bar data={data} height={600} options={options} />
          </div>
        </div>
      </div>
      <div>
        <br></br>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td>Nacionalidade</td>
              <td>Quantidade</td>
            </tr>
          </thead>
          <tbody>
            {result.map((nacionalidade) => (
              <tr>
                <td>{nacionalidade.label}</td>
                <td>{nacionalidade.data}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default InternoNacChart;
