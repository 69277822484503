import {
  ChooseRadio,
  OpenModal,
  SelectBase,
} from "components/CompararDados/DataVerification";
import React, { useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

function CompararDados() {
  const [tipo, setTipo] = useState();
  const [tipoEntidade, setTipoEntidade] = useState();
  const base = useSelector((state) => state.base);

  const handleRadioClick = (tipo) => (e) => {
    setTipo(tipo);
  };

  const entidade = [
    "DOCUMENTO",
    "ENDERECO",
    "FOTOS",
    "MOVIMENTACAO_EXTERNA",
    "MOVIMENTACAO_INTERNA",
    "NOME_SOCIAL",
    "OUTRO_NOME",
    "PESSOA",
    "TELEFONE",
    "VINCULO",
    "VISITACAO",
    "VULGO",
  ];

  return (
    <>
      <>
        <Row>
          <Col>
            <Card style={{ padding: "10px" }} align="center">
              <SelectBase base={base} />
            </Card>
            {base != null ? (
              <Card style={{ padding: "10px" }} align="center">
                <strong>Selecione a entidade: </strong>
                <div>
                  <select onChange={(e) => setTipoEntidade(e.target.value)}>
                    <option selected="selected" disabled="disabled">
                      Selecione
                    </option>
                    {entidade.map((x) => (
                      <option
                        value={x}
                        selected={tipoEntidade == x ? true : false}
                      >
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
              </Card>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            {tipoEntidade != null ? (
              <Card style={{ padding: "10px" }} align="center">
                <Row>
                  <Col>
                    <input
                      onClick={handleRadioClick(1)}
                      type="radio"
                      name="base"
                      value="central"
                      checked={tipo == 1 ? true : false}
                    />
                    Tem no central, mas não tem na base.
                  </Col>
                  <Col>
                    <input
                      onClick={handleRadioClick(2)}
                      type="radio"
                      name="base"
                      value="base"
                      checked={tipo == 2 ? true : false}
                    />
                    Tem na base, mas não tem no central.
                  </Col>
                </Row>
              </Card>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            {(() => {
              switch (tipo) {
                case 1:
                  return (
                    <>
                      <ChooseRadio
                        tipo={1}
                        base={base}
                        entidade={tipoEntidade}
                      />
                    </>
                  );
                case 2:
                  return (
                    <>
                      <ChooseRadio
                        tipo={2}
                        base={base}
                        entidade={tipoEntidade}
                      />
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </Col>
        </Row>
      </>
    </>
  );
}

export default CompararDados;
