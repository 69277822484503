import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import getBaseFromSelect from "store/Base/Base.actions";

function SelectBase() {
  const [integradores, setIntegradores] = useState([]);
  const dispatch = useDispatch();

  function handleSelectChange(e) {
    dispatch(getBaseFromSelect(e.target.value));
  }

  function getIntegradoresConcluidos() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "v1/integradores/status/CONCLUIDO",
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.status != 501) {
          setIntegradores(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getIntegradoresConcluidos();
  }, []);

  return (
    <>
      <strong>Selecione a base: </strong>
      <div>
        <select onChange={(event) => handleSelectChange(event)}>
          <option selected="selected" disabled="disabled">Selecione</option>
          {integradores.map((integrador) => (
            <option value={integrador.base}>{integrador.base}</option>
          ))}
        </select>
      </div>
    </>
  );
}

export default SelectBase;
