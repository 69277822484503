import React from "react";
import "./estilo.css";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";

const Paginacao = ({ pages, currentPage, setCurrentPage }) => {
  return (
    <div style={{ width: "10%", height: "20px" }}>
      <Pagination>
        {currentPage == 0 ? (
          <>
            <Pagination.Item
              key={0}
              active={0 === currentPage ? true : false}
              id={0}
              onClick={(e) => setCurrentPage(Number(e.target.id))}
            >
              {1}
            </Pagination.Item>
          </>
        ) : (
          <>
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
            ></Pagination.Prev>

            <Pagination.Item
              key={0}
              active={0 === currentPage ? true : false}
              id={0}
              onClick={(e) => setCurrentPage(Number(e.target.id))}
            >
              {1}
            </Pagination.Item>
          </>
        )}

        {currentPage > 1 ? <Pagination.Ellipsis /> : ""}

        {Array.from(Array(pages), (item, index) => {
          if (
            ((index >= currentPage - 4 && index <= currentPage + 4) ||
              (currentPage < 5 && index < 10) ||
              (currentPage > pages - 10 && index > pages - 5)) &&
            index > 0 &&
            index < pages - 2
          ) {
            return (
              <Pagination.Item
                key={index}
                active={index === currentPage ? true : false}
                id={index}
                onClick={(e) => setCurrentPage(Number(e.target.id))}
              >
                {index + 1}
              </Pagination.Item>
            );
          }
        })}

        {currentPage < pages - 3 ? <Pagination.Ellipsis /> : ""}

        {(() => {
          if (pages > 1) {
            return currentPage >= pages - 2 ? (
              <>
                <Pagination.Item
                  key={pages - 1}
                  active={pages - 1 === currentPage ? true : false}
                  id={pages - 1}
                  onClick={(e) => setCurrentPage(Number(e.target.id))}
                >
                  {pages - 1}
                </Pagination.Item>
              </>
            ) : (
              <>
                <Pagination.Item
                  key={pages - 1}
                  active={pages - 1 === currentPage ? true : false}
                  id={pages - 1}
                  onClick={(e) => setCurrentPage(Number(e.target.id))}
                >
                  {pages - 1}
                </Pagination.Item>

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                ></Pagination.Next>
              </>
            );
          } else {
            return currentPage >= pages - 2 ? null : (
              <>
                <Pagination.Item
                  key={pages - 1}
                  active={pages - 1 === currentPage ? true : false}
                  id={pages - 1}
                  onClick={(e) => setCurrentPage(Number(e.target.id))}
                >
                  {pages - 1}
                </Pagination.Item>

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                ></Pagination.Next>
              </>
            );
          }
        })()}
      </Pagination>
    </div>
  );
};

export default Paginacao;
