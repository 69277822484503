import React from "react";
import TableIntegrador from "components/Table/TableIntegrador";

function Settings() {
  return (
    <div>
      <TableIntegrador />
    </div>
  );
}

export default Settings;
