import React from "react";
import { Label } from "reactstrap";

const SeletorDePagina = ({ itensPerPage, setItensPerPage }) => {
  return (
    <div class="form-group col-md-4">
      <Label class="my-1 mr-2">Itens por página:</Label>
      <select
        class="custom-select my-1 mr-sm-2"
        value={itensPerPage}
        onChange={(e) => setItensPerPage(Number(e.target.value))}
        style={{width: "70px"}}
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={30}>30</option>
      </select>
      
    </div>
  );
};

export default SeletorDePagina;
