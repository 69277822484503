import CardAdvogado from "components/CardEntidade/CardAdvogado";
import CardDocumento from "components/CardEntidade/CardDocumento";
import CardEndereco from "components/CardEntidade/CardEndereco";
import CardFotos from "components/CardEntidade/CardFotos";
import CardInterno from "components/CardEntidade/CardInterno";
import CardMovimentacaoExterna from "components/CardEntidade/CardMovimentacaoExterna";
import CardMovimentacaoInterna from "components/CardEntidade/CardMovimentacaoInterna";
import CardNomeSocial from "components/CardEntidade/CardNomeSocial";
import CardOutroNome from "components/CardEntidade/CardOutroNome";
import CardTelefone from "components/CardEntidade/CardTelefone";
import CardUnidade from "components/CardEntidade/CardUnidade";
import CardVinculo from "components/CardEntidade/CardVinculo";
import CardVisita from "components/CardEntidade/CardVisita";
import CardVisitacao from "components/CardEntidade/CardVisitacao";
import CardVulgo from "components/CardEntidade/CardVulgo";
import SelectBase from "components/SelectBase/SelectBase";
import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

function Contador() {
  const [controller, setController] = useState();

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card align="center">
              <SelectBase />
              <Col>
                <Button
                  variant="default"
                  size="sm"
                  style={{ marginTop: "15px" , marginBottom: "5px"}}
                  onClick={() => setController(!controller)}
                >
                  Calcular
                </Button>
              </Col>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="3.0">
            <CardAdvogado controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardDocumento controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardEndereco controller={controller} />
          </Col>
        </Row>
        <Row>
          <Col lg="3.0">
            <CardFotos controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardInterno controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardMovimentacaoExterna controller={controller} />
          </Col>
        </Row>
        <Row>
          <Col lg="3.0">
            <CardMovimentacaoInterna controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardNomeSocial controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardOutroNome controller={controller} />
          </Col>
        </Row>
        <Row>
          <Col lg="3.0">
            <CardTelefone controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardUnidade controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardVinculo controller={controller} />
          </Col>
        </Row>
        <Row>
          <Col lg="3.0">
            <CardVisita controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardVisitacao controller={controller} />
          </Col>
          <Col lg="3.0">
            <CardVulgo controller={controller} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contador;
