/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Contador from "views/Count.js";
import Icons from "views/Icons.js";
import Settings from "views/Settings";
import Mapeamento from "views/Mapeamento.js";
import SelectChart from "views/SelectChart.js";
import CompararDados from "views/CompararDados.js";


const dashboardRoutes = [
  {
    path: "/contador",
    name: "Contador",
    icon: "nc-icon nc-notes",
    component: Contador,
    layout: "/admin",
  },
  {
    path: "/integradores",
    name: "Integradores",
    icon: "nc-icon nc-settings-gear-64",
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/mapeamento",
    name: "Mapeamentos",
    icon: "nc-icon nc-refresh-02",
    component: Mapeamento,
    layout: "/admin",
  },

  {
    path: "/graficos",
    name: "Gráficos",
    icon: "nc-icon nc-chart-bar-32",
    component: SelectChart,
    layout: "/admin",
  },

  {
    path: "/comparar-dados",
    name: "Comparar Dados",
    icon: "nc-icon nc-single-copy-04",
    component: CompararDados,
    layout: "/admin",
  },
  
];

export default dashboardRoutes;
