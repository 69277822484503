import React, { useState, useEffect, useRef, useContext } from "react";
import { Chart as ChartJS, BarElement } from "chart.js";
import { Bar } from "react-chartjs-2";
import { CountNoSisdepen } from "./RequisicaoAPI/Counts";
import { Spinner } from "react-bootstrap";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { TimerContext } from "contexts/timer";
import { validateDate } from "./Validacao/regex.js";

ChartJS.register(BarElement);

const EstatisticaGeral = () => {
  let bases = [
    "AC",
    "AL",
    "AM",
    "AP",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MG",
    "MJ",
    "MS",
    "MT",
    "PA",
    "PB",
    "PE",
    "PI",
    "PR",
    "RJ",
    "RN",
    "RO",
    "RR",
    "RS",
    "SC",
    "SE",
    "SP",
    "TO",
  ];

  let date = new Date();
  const [dataInicio, setDataInicio] = useState(null);  
  const [dataFim, setDataFim] = useState(new Date().toLocaleString("pt-BR"));
  const [time, setTime] = useState(90000);
  const { timer, setTimer } = useContext(TimerContext);
  const [regexDateInitialErr, setRegexDateInitialErr] = useState(false);
  const [regexDateFinalErr, setRegexDateFinalErr] = useState(false);
  const [estado, setEstado] = useState("Brasil");
  const [loading, setLoading] = useState("hidden");
  const [baseResult, setBaseResult] = useState({
    qtdInterno: "-",
    qtdAdvogado: "-",
    qtdVisita: "-",
    qtdFoto: "-",
    qtdMovExt: "-",
    qtdVisitacao: "-",
  });
  const [dataChart, setDataChart] = useState({
    labels: [
      "Internos",
      "Advogados",
      "Visitas",
      "Fotos",
      "Movimentações Externas",
      "Visitações",
    ],
    datasets: [
      {
        // label: "Internos",
        data: [0, 0, 0, 0, 0, 0],
        backgroundColor: [
          "#21B3AA",
          "#1C1C1C",
          "#836FFF",
          "#1E90FF",
          "#FF69B4",
          "#FF8C00",
        ],
      },
    ],
  });


  function adicionaZero(numero) {
    if (numero <= 9) return "0" + numero;
    else return numero;
  }

  function validacaoData() {
    if (
      (dataInicio == "" ||
        validateDate.test(dataInicio) == true ||
        dataInicio == null) &&
      (validateDate.test(dataFim) == true || dataFim == null)
    ) {
      setRegexDateInitialErr(false);
      setRegexDateFinalErr(false);
      document.getElementById("inputFinal").style.borderColor = "black";
      document.getElementById("inputInicial").style.borderColor = "black";
      handleButtonClick();
    } else if (
      (dataInicio == "" || dataInicio == null) &&
      validateDate.test(dataFim) == false
    ) {
      setRegexDateFinalErr(true);
      setRegexDateInitialErr(false);
      document.getElementById("inputFinal").focus();
      document.getElementById("inputFinal").style.borderColor = "red";
      document.getElementById("inputInicial").style.borderColor = "black";
    } else if (
      validateDate.test(dataInicio) == false &&
      (dataFim == "" || dataFim == null)
    ) {
      setRegexDateFinalErr(false);
      setRegexDateInitialErr(true);
      document.getElementById("inputInicial").focus();
      document.getElementById("inputFinal").style.borderColor = "black";
      document.getElementById("inputInicial").style.borderColor = "red";
    } else if (
      validateDate.test(dataInicio) == false &&
      validateDate.test(dataFim) == false
    ) {
      setRegexDateFinalErr(true);
      setRegexDateInitialErr(true);
      document.getElementById("inputInicial").focus();
      document.getElementById("inputInicial").style.borderColor = "red";
      document.getElementById("inputFinal").style.borderColor = "red";
    } else if (validateDate.test(dataInicio) == false) {
      setRegexDateInitialErr(true);
      document.getElementById("inputInicial").focus();
      document.getElementById("inputInicial").style.borderColor = "red";
    } else if (validateDate.test(dataFim) == false) {
      setRegexDateFinalErr(true);
      document.getElementById("inputFinal").focus();
      document.getElementById("inputFinal").style.borderColor = "red";
    }
  }

  async function handleButtonClick() {
    setLoading("");

    let base = {
      qtdInterno: 0,
      qtdAdvogado: 0,
      qtdVisita: 0,
      qtdFoto: 0,
      qtdMovExt: 0,
      qtdVisitacao: 0,
    };

    base.qtdInterno = await CountNoSisdepen(
      "internos",
      dataInicio,
      dataFim,
      estado,
      time
    );

    base.qtdAdvogado = await CountNoSisdepen(
      "advogados",
      dataInicio,
      dataFim,
      estado,
      time
    );

    base.qtdVisita = await CountNoSisdepen(
      "visitas",
      dataInicio,
      dataFim,
      estado,
      time
    );
    base.qtdFoto = await CountNoSisdepen(
      "fotos",
      dataInicio,
      dataFim,
      estado,
      time
    );
    base.qtdMovExt = await CountNoSisdepen(
      "movimentacoes/externas",
      dataInicio,
      dataFim,
      estado,
      time
    );
    base.qtdVisitacao = await CountNoSisdepen(
      "visitacoes",
      dataInicio,
      dataFim,
      estado,
      time
    );

    setBaseResult(base);
    setLoading("hidden");

    var data = {
      labels: [
        "Internos",
        "Advogados",
        "Visitas",
        "Fotos",
        "Movimentações Externas",
        "Visitações",
      ],
      datasets: [
        {
          data: [
            base.qtdInterno,
            base.qtdAdvogado,
            base.qtdVisita,
            base.qtdFoto,
            base.qtdMovExt,
            base.qtdVisitacao,
          ],
          backgroundColor: [
            "#21B3AA",
            "#1C1C1C",
            "#836FFF",
            "#1E90FF",
            "#FF69B4",
            "#FF8C00",
          ],
        },
      ],
    };

    setDataChart(data);
  }

  var options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 70,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
    },
  };

  useEffect(() => {
    clearInterval(timer);
    setTimer(setInterval(validacaoData, time));
    setDataInicio(dataInicio);
    setDataFim(dataFim);
  }, [time, dataInicio, dataFim]);

  return (
    <>
      <Row>
        <Col>
          <Card align="center" style={{ height: 240, padding: 10 }}>
            <Row align="center">
              <Col>
                <strong>Selecione a base: </strong>
                <div>
                  <select
                    style={{ height: 30, borderRadius: 5 }}
                    onChange={(event) => setEstado(event.target.value)}
                  >
                    <option value={null}> Brasil </option>
                    {bases.map((estado) => (
                      <option value={estado}>{estado}</option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            <Row align="center" style={{ marginTop: 10 }}>
              <Col>
                <div>
                  <strong>Data de início dos resultados:</strong>
                </div>
                <input
                  id="inputInicial"
                  value={dataInicio}
                  onChange={(event) => {
                    if (event.target.value != "") {
                      setDataInicio(event.target.value);
                    } else {
                      setDataInicio(null);
                    }
                  }}
                  style={{
                    height: 30,
                    borderRadius: 5,
                  }}
                  type="text"
                  placeholder="00/00/00 00:00:00"
                ></input>
                {regexDateInitialErr && (
                  <p style={{ color: "red" }}>Digite uma data válida!</p>
                )}
                  <Row style={{ marginTop: 25 }}>
                  <Col>
                    <Button
                          size="sm"
                          variant="light"
                          active
                          onClick={() => {
                            //Passando o timestamp atual para o formato unix
                            let unixTimestamp = Math.floor(date.getTime() / 1000);
                            let newUnixTimestamp = (unixTimestamp - 600)*1000;
                            let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                                new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                            setDataInicio(dataAtualizada);
                            setDataFim(new Date().toLocaleString("pt-BR"))
                          }}
                        >
                          ÚLTIMOS 10MIN
                        </Button>                  
                        &nbsp;
                        <Button
                          size="sm"
                          variant="light"
                          active
                          onClick={() => {
                            //Passando o timestamp atual para o formato unix
                            let unixTimestamp = Math.floor(date.getTime() / 1000);
                            let newUnixTimestamp = (unixTimestamp - 1800)*1000;
                            let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                                new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                            setDataInicio(dataAtualizada); 
                            setDataFim(new Date().toLocaleString("pt-BR"))                 
                          }}
                        >
                          ÚLTIMOS 30MIN
                        </Button>
                        &nbsp;
                        <Button
                          size="sm"
                          variant="light"
                          active
                          onClick={() => {  
                              //Passando o timestamp atual para o formato unix                          
                              let unixTimestamp = Math.floor(date.getTime() / 1000);
                              let newUnixTimestamp = (unixTimestamp - 3600)*1000;
                              let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                                  new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                              setDataInicio(dataAtualizada);   
                              setDataFim(new Date().toLocaleString("pt-BR"));                    
                          }}
                        >
                          ÚLTIMA 1H
                        </Button>
                      </Col>  
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col>
                    <Button
                        size="sm"
                        variant="light"
                        active
                        onClick={() => {
                          //Passando o timestamp atual para o formato unix
                          let unixTimestamp = Math.floor(date.getTime() / 1000);
                          let newUnixTimestamp = (unixTimestamp - 43200)*1000;
                          let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                              new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                          setDataInicio(dataAtualizada);
                          setDataFim(new Date().toLocaleString("pt-BR"));                   
                        }}
                      >
                        ÚLTIMAS 12H
                      </Button>                  
                      &nbsp;
                      <Button
                        size="sm"
                        variant="light"
                        active
                        onClick={() => {
                          //Passando o timestamp atual para o formato unix
                          let unixTimestamp = Math.floor(date.getTime() / 1000);
                          let newUnixTimestamp = (unixTimestamp - 86400)*1000;
                          let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                              new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                          setDataInicio(dataAtualizada); 
                          setDataFim(new Date().toLocaleString("pt-BR"));                   
                        }}
                      >
                        ÚLTIMAS 24H
                      </Button>
                      &nbsp;
                      <Button
                        size="sm"
                        variant="light"
                        active
                        onClick={() => {
                          //Passando o timestamp atual para o formato unix
                          let unixTimestamp = Math.floor(date.getTime() / 1000);
                          let newUnixTimestamp = (unixTimestamp - 172800)*1000;
                          let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                              new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                          setDataInicio(dataAtualizada);  
                          setDataFim(new Date().toLocaleString("pt-BR"));                
                        }}
                      >
                        ÚLTIMAS 48H
                      </Button>
                    </Col>
                    
                </Row>
              </Col>
              <Col>
                <strong>
                  Selecione a taxa de atualização do gráfico e tabela:{" "}
                </strong>
                <div>
                  <select
                    style={{ width: 50, height: 30, borderRadius: 5 }}
                    onChange={(event) => setTime(event.target.value)}
                  >
                    <option value={90000}>90</option>
                    <option value={60000}>60</option>
                    <option value={30000}>30</option>
                  </select>
                  <strong> segundos</strong>
                </div>
                <Button 
                  size="lm" 
                  onClick={validacaoData} 
                  style={{ marginTop: 20 }} 
                  variant="outline-info"
                  active
                >
                  Calcular
                </Button>
              </Col>
              
              <Col>
                <div>
                  <strong>Data de término dos resultados:</strong>
                </div>
                <input
                  id="inputFinal"
                  value={dataFim}
                  onChange={(event) => {
                    if (event.target.value != "") {
                      setDataFim(event.target.value);
                    } else {
                      setDataFim(null);
                    }
                  }}
                  style={{
                    height: 30,
                    borderRadius: 5,
                  }}
                  type="text"
                  placeholder="00/00/00 00:00:00"
                ></input>
                {regexDateFinalErr && (
                  <p style={{ color: "red" }}>Digite uma data válida!</p>
                )}
              </Col>
            </Row>            
          </Card>
        </Col>
      </Row>
      <div>
        <h3 className="titulo">GRÁFICO DE ESTATÍSTICAS POR ENTIDADE</h3>
      </div>
      <div>
        <Bar data={dataChart} height={600} options={options} />
      </div>
      <div>
        <br></br>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td>
                <strong>Base</strong>
              </td>
              <td>
                <strong>Internos</strong>
              </td>
              <td>
                <strong>Advogados</strong>
              </td>
              <td>
                <strong>Visitas</strong>
              </td>
              <td>
                <strong>Fotos</strong>
              </td>
              <td>
                <strong>Movimentações Externas</strong>
              </td>
              <td>
                <strong>Visitações</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{estado == null ? "Brasil" : estado}</td>
              <td>
                {loading != "hidden" ? (
                  <Spinner
                    style={{ visibility: loading }}
                    animation="border"
                  ></Spinner>
                ) : (
                  baseResult.qtdInterno
                )}
              </td>
              <td>
                {loading != "hidden" ? (
                  <Spinner
                    style={{ visibility: loading }}
                    animation="border"
                  ></Spinner>
                ) : (
                  baseResult.qtdAdvogado
                )}
              </td>
              <td>
                {loading != "hidden" ? (
                  <Spinner
                    style={{ visibility: loading }}
                    animation="border"
                  ></Spinner>
                ) : (
                  baseResult.qtdVisita
                )}
              </td>
              <td>
                {loading != "hidden" ? (
                  <Spinner
                    style={{ visibility: loading }}
                    animation="border"
                  ></Spinner>
                ) : (
                  baseResult.qtdFoto
                )}
              </td>
              <td>
                {loading != "hidden" ? (
                  <Spinner
                    style={{ visibility: loading }}
                    animation="border"
                  ></Spinner>
                ) : (
                  baseResult.qtdMovExt
                )}
              </td>
              <td>
                {loading != "hidden" ? (
                  <Spinner
                    style={{ visibility: loading }}
                    animation="border"
                  ></Spinner>
                ) : (
                  baseResult.qtdVisitacao
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default EstatisticaGeral;
