/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";

import store from "./store/store";
import { Provider } from "react-redux";

import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import TimerProvider from "contexts/timer";

keycloak
  .init({
    onLoad: "login-required",
  })
  .then((success) => {
    if (success)
      ReactDOM.render(
        <ReactKeycloakProvider authClient={keycloak}>
          <TimerProvider>
            <BrowserRouter>
              <Provider store={store}>
                <Switch>
                  <Route
                    path="/admin"
                    render={(props) => <AdminLayout {...props} />}
                  />
                  <Redirect from="/" to="/admin/integradores" />
                </Switch>
              </Provider>
            </BrowserRouter>
          </TimerProvider>
        </ReactKeycloakProvider>,
        document.getElementById("root")
      );
  });
