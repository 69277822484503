import { createStore, combineReducers } from "redux";
import BaseReducer from "./Base/Base.reducer";

const rootReducer = combineReducers({
  base: BaseReducer,
});

const store = createStore(rootReducer);

export default store;
