import { useEffect, useState } from "react";

/**
 * Faz contagem dos dados do SISDEPEN Indivíduos
 * @param {*} entidade // entidade que irá fazer o count, usar o padrão conforme URL da API, exemplos: internos, fotos, movimentacoes/externas, visitacoes
 * @param {*} base // parâmetro opcional, especificar uma base, caso queira
 * @param {*} data // intervalo da data do inicio e final
 * @returns
 */

export async function CountNoSisdepen(
  entidade,
  dataInicio,
  dataFim,
  baseParam,
  time
) {
  let teste = [];
  let flag = baseParam;
  if (dataInicio == "" || dataInicio == null || dataInicio == undefined) { 
    dataInicio = "01/01/1900 00:00:00"
  };
  if (dataFim == "" || dataFim == null || dataFim == new Date().toLocaleString()) { 
    dataFim = "01/01/3000 00:00:00"
  };
 
  // useEffect(() => {
  const find = {
    base: flag == "Brasil" ? null : flag,
    initialDate: dataInicio.split("/").join("-") ,
    finalDate: dataFim.split("/").join("-"),
  };
  
  return await fetch(
    process.env.REACT_APP_URL_API_INTEGRADORES +
      "v1/count/ultimas-entradas/" +
      entidade,
    {
      mode: "cors",
      credentials: "include",
      method: "POST",
      body: JSON.stringify(find),
      headers: new Headers({
        authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
        "content-type": "application/json",
      }),
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Erro na function CountNoSisdepen: " + error);
    });
}

/**
 * Faz busca dos dados Nacionalidades e por base pelo SISDEPEN
 * @param {*} base // parâmetro opcional, especificar uma base, caso queira
 * @param {*} time // intervalo da taxa de atualização do gráfico e tabela
 * @returns
 */
export function NacionalidadesPorBase(baseParam, time) {
  const [qtdEntidade, setQtdEntidade] = useState([]);

  let path = "v1/count/nacionalidades";
  const flag = baseParam;

  if (flag != "") {
    path = path + "?base=" + baseParam;
  }

  function gerar_cor_hexadecimal() {
    return (
      "#" +
      parseInt(Math.random() * 0xfff)
        .toString(16)
        .padStart(3, "0")
    );
  }

  useEffect(() => {
    const data = [];
    const fetcher = async () => {
      await fetch(
        process.env.REACT_APP_URL_API_INTEGRADORES + path,

        {
          mode: "cors",
          credentials: "include",
          method: "GET",
          headers: new Headers({
            authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
            "content-type": "application/json",
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              // console.log(json);
              json.map((item) =>
                data.push({
                  label: item[0],
                  data: [item[1]],
                  backgroundColor: gerar_cor_hexadecimal(),
                })
              );
              setQtdEntidade(data);
            });
          }
        })
        .catch(function (error) {
          console.log("Erro na function CountNoSisdepen: " + error);
        });
    };
    fetcher();
    setInterval(fetcher, time);
  }, [baseParam, time]);

  return qtdEntidade;
}

export function NacionalidadesPorBaseMapeados(baseParam, time) {
  const [qtdEntidade, setQtdEntidade] = useState([]);

  let path = "v1/count/nacionalidades-sisdepen";
  const flag = baseParam;

  if (flag != "") {
    path = path + "?base=" + baseParam;
  }

  function gerar_cor_hexadecimal() {
    return (
      "#" +
      parseInt(Math.random() * 0xfff)
        .toString(16)
        .padStart(3, "0")
    );
  }

  useEffect(() => {
    const data = [];
    const fetcher = async () => {
      await fetch(
        process.env.REACT_APP_URL_API_INTEGRADORES + path,

        {
          mode: "cors",
          credentials: "include",
          method: "GET",
          headers: new Headers({
            authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
            "content-type": "application/json",
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              json.map((item) =>
                data.push({
                  label: item[0],
                  data: [item[1]],
                  backgroundColor: gerar_cor_hexadecimal(),
                })
              );
              setQtdEntidade(data);
            });
          }
        })
        .catch(function (error) {
          console.log("Erro na function CountNoSisdepen: " + error);
        });
    };
    fetcher();
    setInterval(fetcher, time);
  }, [baseParam, time]);

  return qtdEntidade;
}
