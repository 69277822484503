import React, { createContext, useState,  } from "react";

export const TimerContext = createContext({});

function TimerProvider({ children }) {
  const [timer, setTimer] = useState(0);

  return (
    <TimerContext.Provider
      value={{timer: timer, setTimer}}
    >
      {children}
    </TimerContext.Provider>
  );
}

export default TimerProvider;