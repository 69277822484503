import React, { useState, useEffect } from "react";
import { Button, Card, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";

function CardVinculo(props) {
  const base = useSelector((state) => state.base);
  const [baseCount, setBaseCount] = useState("-");
  const [sisdepenCount, setSisdepenCount] = useState("-");
  const [baseDate, setBaseDate] = useState("-");
  const [sisdepenDate, setSisdepenDate] = useState("-");
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState();

  function getCountValues() {
    getCountFromBase();
    getCountFromSisdepen();
  }

  function getCountFromBase() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "v1/integradores/count/vinculos/base?base=" +
        base,
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var count = JSON.parse(data);
        setBaseCount(count);
        setBaseDate(Date().toLocaleUpperCase());
      })
      .catch(function (error) {
        setBaseCount("-");
        setBaseDate(Date().toLocaleUpperCase());
        showAlertError(error);
      });
  }

  function getCountFromSisdepen() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "v1/count/vinculos/base?base=" +
        base,
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var count = JSON.parse(data);
        setSisdepenCount(count);
        setSisdepenDate(Date().toLocaleUpperCase());
      })
      .catch(function (error) {
        showAlertError(error);
      });
  }

  function showAlertError(error) {
    setAlert(true);
    if (base == null || base == "") {
      setError("Error: o campo base está vazio.");
    } else {
      setError(error.toString());
    }
    setTimeout(function () {
      setAlert(false);
    }, 2000);
  }

  useEffect(() => {
    if (props.controller != null) {
      getCountValues();
    }
  }, [props.controller]);

  return (
    <div>
      <Alert style={{ width: "32rem" }} show={alert} variant="danger">
        <p>{error}</p>
      </Alert>
      <Card border="dark" style={{ height: "21.8rem", width: "32rem" }} as="h3">
        <Card.Header>
          <strong>Vinculo</strong>
          <Button
            onClick={getCountValues}
            variant="success"
            style={{ marginLeft: "56%" }}
          >
            Calcular
          </Button>
        </Card.Header>
        <Card.Body>
          <Card.Title style={{ marginBottom: "3%" }} as="h3">
           <hr/>
            <strong>Estado:</strong>
            <Button
              onClick={getCountFromBase}
              size="sm"
              style={{ marginLeft: "59%" }}
            >
              Calcular
            </Button>
          </Card.Title>
          <Card.Subtitle>Contagem: {baseCount}</Card.Subtitle>
          <Card.Text></Card.Text>
          <Card.Subtitle>
            Data de Contagem: {baseDate.substring(0, 34)}
          </Card.Subtitle>
          <Card.Title as="h3" style={{ marginBottom: "3%" }}>
           <hr/>
            <strong>Sisdepen: </strong>
            <Button
              onClick={getCountFromSisdepen}
              size="sm"
              style={{ marginLeft: "52%" }}
            >
              Calcular
            </Button>
          </Card.Title>
          <Card.Subtitle>Contagem: {sisdepenCount}</Card.Subtitle>
          <Card.Text></Card.Text>
          <Card.Subtitle>
            Data de Contagem: {sisdepenDate.substring(0, 34)}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CardVinculo;
