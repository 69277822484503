import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import FormTipoDocumento from "components/Mapeamento/MapeamentoTipoDocumento";
import FormTipoMovimentacao from "components/Mapeamento/MapeamentoTipoMovimentacao";
import FormTipoVinculo from "components/Mapeamento/MapeamentoTipoVinculo";
import FormTipoVisitacao from "components/Mapeamento/MapeamentoTipoVisitacao";
import FormTipoFotos from "components/Mapeamento/MapeamentoTipoFotos";
import FormNacionalidades from "components/Mapeamento/MapeamentoNacionalidades";
import FormNaturalidades from "components/Mapeamento/MapeamentoNaturalidades";
import FormRegimes from "components/Mapeamento/MapeamentoRegimes";
import FormSexo from "components/Mapeamento/MapeamentoSexo";
import FormSentenciado from "components/Mapeamento/MapeamentoSentenciado";
import FormMedidaSeguranca from "components/Mapeamento/MapeamentoMedidaSeguranca";
import SelectBase from "components/SelectBase/SelectBase";
import { useSelector } from "react-redux";

export default function Mapeamento() {
  const [tipo, setTipo] = useState();
  const base = useSelector((state) => state.base);
  const [tipoBase, setTipoBase] = useState("");

  // let tipoBase = null;

  function handleSelectChange(e) {
    setTipo(e.target.value);
    setTipoBase(base);
  }

  if (base != tipoBase && tipo != null) {
    document.getElementById("tiposEscolhas").selectedIndex = 0;
  }

  return (
    <div>
      <Row>
        <Col>
          <Card style={{ padding: "10px" }} align="center">
            <SelectBase />
          </Card>
        </Col>
      </Row>
      {base != null ? (
        <Row>
          <Col>
            <Card style={{ padding: "10px" }} align="center">
              <strong>Selecione o tipo de mapeamento: </strong>
              <div>
                <select
                  id="tiposEscolhas"
                  onChange={(event) => handleSelectChange(event)}
                >
                  <option selected="selected" disabled="disabled">
                    Selecione
                  </option>
                  <option value="cidade">Cidade</option>
                  <option value="medidaSeguranca">Medida de Segurança</option>
                  <option value="pais">Pais</option>
                  <option value="tiposDocumento">Tipos de Documento</option>
                  <option value="tiposFotos">Tipos de Fotos</option>
                  <option value="tiposMovimentacao">
                    Tipos de Movimentação
                  </option>
                  <option value="tiposVinculo">Tipos de Vínculo</option>
                  <option value="tiposVisitacao">Tipos de Visitação</option>
                  <option value="regime">Regime</option>
                  <option value="tiposSexo">Sexo</option>
                  <option value="sentenciado">Sentenciado</option>
                </select>
              </div>
            </Card>
          </Col>
        </Row>
      ) : null}

      {base == tipoBase ? (
        <Row>
          <Col>
            {(() => {
              switch (tipo) {
                case "tiposDocumento":
                  return <FormTipoDocumento tipo={tipo} />;
                case "tiposFotos":
                  return <FormTipoFotos tipo={tipo} />;
                case "tiposMovimentacao":
                  return <FormTipoMovimentacao tipo={tipo} />;
                case "tiposVinculo":
                  return <FormTipoVinculo tipo={tipo} />;
                case "tiposVisitacao":
                  return <FormTipoVisitacao tipo={tipo} />;
                case "pais":
                  return <FormNacionalidades tipo={tipo} />;
                case "cidade":
                  return <FormNaturalidades tipo={tipo} />;
                case "regime":
                  return <FormRegimes tipo={tipo} />;
                case "tiposSexo":
                  return <FormSexo tipo={tipo} />;
                case "sentenciado":
                  return <FormSentenciado tipo={tipo} />;
                case "medidaSeguranca":
                  return <FormMedidaSeguranca tipo={tipo} />;
                default:
                  return null;
              }
            })()}
          </Col>
        </Row>
      ) : null}
    </div>
  );
}
