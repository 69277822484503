import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import getBaseFromSelect from "store/Base/Base.actions";
import {
  Alert,
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
  CloseButton,
} from "react-bootstrap";
import SeletorDePagina from "./SeletorDePagina";
import Paginacao from "./Paginacao";

export function ChooseRadio(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(5);
  const [dadosRecebidos, setResult] = useState([]);
  const [loading, setLoading] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [alertErr, setAlertErr] = useState(false);
  const [alertOk, setAlertOk] = useState(false);
  const [carregar, setCarregar] = useState("hidden");
  const [posicao, setPosicao] = useState(null);

  //variaveis para a paginação
  const pages = Math.ceil(dadosRecebidos.length / itensPerPage);
  const startIndex = currentPage * itensPerPage;
  const endIndex = startIndex + itensPerPage;
  const currentItens = dadosRecebidos.slice(startIndex, endIndex);

  function getRadioBase() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "/v1/sincronismo/diferencas/tem-no-integrador-nao-tem-na-api/" +
        props.base +
        "/" +
        props.entidade,
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.status != 501) {
          setResult(data);
        }
      })
      .catch(function (error) {
        setLoading("hidden");
        console.log(error);
      })
      .finally(function (data) {
        setLoading("hidden");
      });
  }

  function getRadioCentral() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "/v1/sincronismo/diferencas/tem-na-api-nao-tem-no-integrador/" +
        props.base +
        "/" +
        props.entidade,
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.status != 501) {
          setResult(data);
        }
      })
      .catch(function (error) {
        setLoading("hidden");
        console.log(error);
      })
      .finally(function (data) {
        setLoading("hidden");
      });
  }

  function sincronizarIndividualmente(key) {
    const id = key.substring(0, key.indexOf("|") - 1);

    return (
      fetch(
        process.env.REACT_APP_URL_API_INTEGRADORES +
          "/v1/sincronismo/individual/id/" +
          id +
          "/base/" +
          props.base,
        {
          mode: "cors",
          credentials: "include",
          method: "GET",
          headers: new Headers({
            authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
            "content-type": "application/json",
          }),
        }
      )
        .then(function (response) {
          if (response.status != 204) {
            setResult(
              dadosRecebidos.filter((item) => {
                console.log(item, key);
                return item != key;
              })
            );
          }
          return response;
        })
        .catch(function (error) {
          showAlertError();
          setMensagem(error);
          console.log(error);
        })
        .finally(function (data) {
          setCarregar("hidden");
        })
    );
  }

  function sincronizarTodosPendentes() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "/v1/sincronismo/dados-pendentes/base/" +
        props.base +
        "/entidade/" +
        props.entidade,
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        console.log(data);
      })
      .catch(function (error) {
        showAlertError();
        setMensagem(error);
      })
      .finally(function (data) {
        setLoading("hidden");
        getRadioBase();
      });
  }

  function showAlertOk() {
    setAlertOk(true);
    setTimeout(function () {
      setAlertOk(false);
    }, 2000);
  }

  function showAlertError() {
    setAlertErr(true);
    setTimeout(function () {
      setAlertErr(false);
    }, 2000);
  }

  useEffect(() => {
    {
      props.tipo == 1 ? getRadioCentral() : getRadioBase();
      setLoading("");
      setResult([]);
    }
  }, [props.tipo, props.entidade, props.base]);

  useEffect(() => {
    setCurrentPage(0);
  }, [itensPerPage]);

  return (
    <div>
     
      <Alert show={alertErr} variant="danger">
        <p>{mensagem}</p>
      </Alert>
      <Alert show={alertOk} style={{ backgroundColor: "green" }}>
        <p>{mensagem}</p>
      </Alert>
      <SeletorDePagina
        itensPerPage={itensPerPage}
        setItensPerPage={setItensPerPage}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>PENDENTES</th>
            <th>SINCRONIZAR</th>
          </tr>
        </thead>
        <tbody>
          {currentItens.map((item, i) => (
            <tr>
              <td>{item}</td>
              {props.tipo == 1 ? (
                <td>
                  <Button
                    // onClick={() => sincronizarIndividualmente(item)}
                    variant="danger"
                    size="sm"
                  >
                    Excluir
                  </Button>
                </td>
              ) : (
                <td>
                  {carregar != "hidden" && posicao == i ? (
                    <Spinner
                      style={{ visibility: carregar }}
                      animation="border"
                      role="status"
                    ></Spinner>
                  ) : (
                    <Button
                      value={i}
                      variant="primary"
                      size="sm"
                      onClick={(event) => {
                        sincronizarIndividualmente(item);
                        setCarregar("");
                        setPosicao(event.target.value);
                      }}
                    >
                      Sincronizar
                    </Button>
                  )}
                </td>
              )}
            </tr>
          ))}
          {loading != "hidden" ? (
            <td colspan="3" align="center">
              <Spinner
                style={{ visibility: loading }}
                animation="border"
                role="status"
              ></Spinner>
              Carregando...
            </td>
          ) : null}
          {currentItens.length <= 0 && currentItens.length == null ? (
            <tr>
              <td colspan="3" align="center">
                Não encontrado
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
      {loading == "hidden" && currentItens.length > 0 ? (
        <>
          <Row>
            <Col align="left">
              <Paginacao
                pages={pages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            {props.tipo == 2 ? (
              <Col align="right">
                <Button
                  size="sm"
                  disabled={carregar != "hidden" ? true : false}
                  onClick={() => {
                    sincronizarTodosPendentes();
                    setLoading(""); 
                    setResult([]);                   
                  }}
                >
                  Sincronizar pendentes
                </Button>
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}
    </div>
  );
}

export function SelectBase(props) {
  const [integradores, setIntegradores] = useState([]);
  const dispatch = useDispatch();

  function handleSelectChange(e) {
    dispatch(getBaseFromSelect(e.target.value));
  }

  function getIntegradoresConcluidos() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "v1/integradores/status/CONCLUIDO",
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.status != 501) {
          setIntegradores(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getIntegradoresConcluidos();
  }, []);

  return (
    <>
      <strong>Selecione a base: </strong>
      <div>
        <select onChange={(event) => handleSelectChange(event)}>
          <option selected="selected" disabled="disabled">
            Selecione
          </option>
          {integradores.map((integrador) => (
            <option
              value={integrador.base}
              selected={props.base == integrador.base ? true : false}
            >
              {integrador.base}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
