import React, { useState, useEffect, useContext  } from "react";
import {
  Alert,
  Form,
  Button,
  Card,
  Row,
  Col,
  Table,
  Spinner,
} from "react-bootstrap";
import { CardTitle } from "reactstrap";
import WindowedSelect from "react-windowed-select";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { TimerContext } from "contexts/timer";

function TableIntegradorSimplificado(tipo) {
  const [integradores, setIntegradores] = useState([]);
  const [alertErr, setAlertErr] = useState(false);
  const [alertOk, setAlertOk] = useState(false);
  const [controller, setController] = useState(null);
  const [loading, setLoading] = useState("hidden");
  const [mensagem, setMensagem] = useState();
  const [visibility, setVisibility] = useState("hidden");
  const [nome, setNome] = useState();
  const [ip, setIp] = useState();
  const [status, setStatus] = useState();
  const [integradorUpdate, setIntegradorUpdate] = useState();
  const [newIntegrador, setNewIntegrador] = useState();
  const [time, setTime] = useState(90000);
  const { timer, setTimer } = useContext(TimerContext);

  const options = [
    {
      label: "CANCELADO",
      value: "CANCELADO",
    },
    {
      label: "CONCLUIDO",
      value: "CONCLUIDO",
    },
    {
      label: "PENDENTE",
      value: "PENDENTE",
    },
  ];

  function getIntegradores() {
    return fetch(process.env.REACT_APP_URL_API_INTEGRADORES + "v1/integradores", {
      mode: "cors",
      credentials: "include",
      method: "GET",
      headers: new Headers({
        authorization:
          process.env.REACT_APP_GERENCIAMENTO_KEY,
        "content-type": "application/json",
      }),
    })
      .then(function (response) {
        setLoading("");
        return response.json();
      })
      .then(function (data) {
        if (data.status != 501) {
          setLoading("");
          setIntegradores(data); 
                   
        } else {
          setMensagem("Error: " + data.status + " - " + data.detail);
          showAlertError();
        }
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      })
      .finally(function (data) {
        setLoading("hidden");
      });
  }

  function getIntegradoresConcluidos() {
    return fetch(process.env.REACT_APP_URL_API_INTEGRADORES + "v1/integradores/status/CONCLUIDO", {
      mode: "cors",
      credentials: "include",
      method: "GET",
      headers: new Headers({
        authorization:
          process.env.REACT_APP_GERENCIAMENTO_KEY,
        "content-type": "application/json",
      }),
    })
      .then(function (response) {
        setLoading("");
        return response.json();
      })
      .then(function (data) {
        if (data.status != 501) {
          setLoading("");
          setIntegradores(data);       
        } else {
          setMensagem("Error: " + data.status + " - " + data.detail);
          showAlertError();
        }
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      })
      .finally(function (data) {
        setLoading("hidden");
      });
  }

  function postIntegrador(newIntegrador) {
    const postRequest = {
      base: newIntegrador.base,
      dataIntegracao: newIntegrador.dataIntegracao,
      ip: newIntegrador.ip,
      nome: newIntegrador.nome,
      sincronismoAutomatico: newIntegrador.sincronismoAutomatico,
      status: newIntegrador.status,
    };

    return fetch(process.env.REACT_APP_URL_API_INTEGRADORES + "v1/integradores/", {
      mode: "cors",
      credentials: "include",
      method: "POST",
      body: JSON.stringify(postRequest),
      headers: new Headers({
        authorization:
          process.env.REACT_APP_GERENCIAMENTO_KEY,
        "content-type": "application/json",
      }),
    })
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        setMensagem("Integrador criado com sucesso.");
        showAlertOk();
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      });
  }

  function putIntegrador(integrador) {
    const putRequest = {
      base: integrador.base,
      dataIntegracao: integrador.dataIntegracao,
      ip: ip,
      nome: nome,
      sincronismoAutomatico: integrador.sincronismoAutomatico,
      status: status,
    };

    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES + "v1/integradores/" + integrador.id,
      {
        mode: "cors",
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(putRequest),
        headers: new Headers({
          authorization:
            process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        setMensagem(
          "O integrador da base " + integrador.base + " foi atualizado."
        );
        setController(!controller);
        showAlertOk();
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      })
      .finally(function () {
        setVisibility("hidden");
      });
  }

  function sincronismoAutomatico(base) {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES + "v1/sincronismo/automatico/base/" +
      base,
      {
        mode: "cors",
        credentials: "include",
        method: "PUT",
        headers: new Headers({
          authorization:
            process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setMensagem(
          "O Sincronísmo Automático da base " +
          base +
          " foi setado como " +
          data +
          "."
        );
        setController(!controller);
        showAlertOk();
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      });
  }

  function sincronizarDoInicio(base) {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES + "v1/integradores/sincronizar-do-inicio/base/" +
      base,
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization:
            process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        if (!data.ok) {
          setMensagem("Error: " + data.status + " - " + data.statusText + ".");
          showAlertError();
        } else {
          setMensagem(
            "Sincronizar do Início da base " + base + " foi ativado."
          );
          setController(!controller);
          showAlertWarn();
        }
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      });
  }

  function iniciarSincronizando(base) {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES + "v1/sincronismo/inicio/base/" + base,
      {
        mode: "cors",
        credentials: "include",
        method: "PUT",
        headers: new Headers({
          authorization:
            process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        setMensagem("O Sincronizando da base " + base + " foi ativado.");
        setController(!controller);
        showAlertOk();
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      });
  }

  function pararSincronizando(base) {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES + "v1/sincronismo/termino/base/" + base,
      {
        mode: "cors",
        credentials: "include",
        method: "PUT",
        headers: new Headers({
          authorization:
            process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        setMensagem("O Sincronizando da base " + base + " foi desativado.");
        setController(!controller);
        showAlertOk();
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      });
  }

  const handleButtonClick = (tipo, integrador) => (e) => {
    switch (tipo) {
      case 1:
        return iniciarSincronizando(integrador.base);
      case 2:
        return pararSincronizando(integrador.base);
      case 3:
        return sincronismoAutomatico(integrador.base);
      case 4:
        return sincronizarDoInicio(integrador.base);
      case 5:
        return setter(integrador);
      case 6:
        return putIntegrador(integradorUpdate);
      case 7:
        return postIntegrador();
    }
  };

  const handleSelectChange = (option) => {
    setStatus(option.value);
  };

  function setter(integrador) {
    setIntegradorUpdate(integrador);
    setNome(integrador.nome);
    setIp(integrador.ip);
    setStatus(integrador.status);
    setVisibility("");
  }

  function showAlertOk() {
    setAlertOk(true);
    setTimeout(function () {
      setAlertOk(false);
    }, 2000);
  }

  function showAlertError() {
    setAlertErr(true);
    setTimeout(function () {
      setAlertErr(false);
    }, 2000);
  }

  useEffect(() => {
    if (controller != null) getIntegradores();
  }, [controller]);

  useEffect(() => {
    if (tipo != null) getIntegradoresConcluidos();
  }, [tipo]);

  useEffect(() => {
    clearInterval(timer);
    setTimer(setInterval(getIntegradoresConcluidos, time));       
  }, [time]);

  return (
    <div>
      <Row>
        <Col align="center">
          <Spinner
            style={{ visibility: loading }}
            animation="border"
            role="status"
          ></Spinner>
          <Alert show={alertErr} variant="danger">
            <p>{mensagem}</p>
          </Alert>
          <Alert show={alertOk} style={{ backgroundColor: "green" }}>
            <p>{mensagem}</p>
          </Alert>
          <strong>
            Taxa de atualização da tabela:{" "}
          </strong>
          <div>
            <select
              style={{ width: 95, height: 30, borderRadius: 5 }}
              onChange={(event) => setTime(event.target.value)}
            >
              <option >Selecione</option>
              <option value={5000}>5</option>
              <option value={10000}>10</option>
              <option value={30000}>30</option>
            </select>
            <strong> segundos</strong>
          </div>
        </Col>        
      </Row>
      <Row>
        <Col style={{ marginBottom: "10px" }} align="right">
          <Button onClick={handleButtonClick()} size="sm" variant="success">
            <i className="nc-icon nc-simple-add"></i>
          </Button>
        </Col>
      </Row>

      <Table striped bordered hover>

        <thead>
          <tr>
            <th>Base</th>
            <th>Status</th>
            <th>Início da ultima sincronização</th>
            <th>Término da ultima sincronização</th>
            <th>Frequência Sincronização</th>
            <th>Sincronizando</th>
            <th>Sincronísmo Automático</th>
          </tr>
        </thead>
        <tbody>
          {integradores.map((integrador) => (
            <tr>
              <td>{integrador.base}</td>
              <td>{integrador.status == "CONCLUIDO" ?
                integrador.status : null}</td>
              <td>{integrador.inicioUltimaSincronizacao != null ?
                new Date(integrador.inicioUltimaSincronizacao).toLocaleString("pt-BR") :
                null}
              </td>
              <td>
                {
                  (integrador.sincronizacaoFalhou == true) ?
                  <ReportProblemIcon fontSize="large" color="secondary"></ReportProblemIcon>
                  :
                    (integrador.terminoUltimaSincronizacao < integrador.inicioUltimaSincronizacao) ?
                      <Spinner
                        size="sm"
                        animation="border"
                      ></Spinner>
                    :
                    new Date(integrador.terminoUltimaSincronizacao).toLocaleString("pt-BR")
                }

              </td>
              <td>{integrador.frequenciaSincronizacao}</td>
              <td>
                {integrador.sincronizando == true ?
                  <Button
                    onClick={handleButtonClick(1, integrador)}
                    variant="success"
                    size="sm"
                    data-toggle="tooltip"
                    title="Desativar"
                    style={{ marginLeft: "15px" }}>
                    <i class="fas fa-check"></i>
                  </Button>
                  :
                  <Button
                    onClick={handleButtonClick(2, integrador)}
                    variant="danger"
                    size="sm"
                    data-toggle="tooltip"
                    title="Ativar"
                    style={{ marginLeft: "15px" }}
                  >
                    <i class="fas fa-exclamation"></i>
                  </Button>
                }

              </td>
              <td align="center">
                {integrador.sincronismoAutomatico == true ?
                  <Button
                    onClick={handleButtonClick(3, integrador)}
                    variant="success"
                    size="sm"
                    data-toggle="tooltip"
                    title="Desativar"
                    style={{ marginLeft: "15px" }}
                  >
                    <i class="fas fa-check"></i>
                  </Button> :
                  <Button
                    onClick={handleButtonClick(3, integrador)}
                    variant="danger"
                    size="sm"
                    data-toggle="tooltip"
                    title="Ativar"
                    style={{ marginLeft: "15px" }}
                  >
                    <i class="fa fa-exclamation"></i>
                  </Button>}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Row>
        <Col align="center">
          <Card
            style={{
              visibility: visibility,
              height: "21.8rem",
              width: "32rem",
              padding: "10px",
            }}
          >
            <Form>
              <Form.Group className="mb-3" controlId="formBasicNome">
                <Form.Label>Nome do Integrador</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o nome..."
                  onChange={(event) => setNome(event.target.value)}
                  value={nome}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicIP">
                <Form.Label>IP do Integrador</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o IP..."
                  onChange={(event) => setIp(event.target.value)}
                  value={ip}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicStatus">
                <Form.Label>Status do Integrador</Form.Label>
                <WindowedSelect
                  onChange={(event) => handleSelectChange(event)}
                  options={options}
                ></WindowedSelect>
              </Form.Group>
              <Button variant="primary" onClick={handleButtonClick(6, null)}>
                Atualizar
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TableIntegradorSimplificado;
