import React, { useState } from "react";
import TableIntegrador from "components/Integradores/InformacoesCompletas";
import TableIntegradorSimplificado from "components/Integradores/Simplificado";

function SelectTipoIntegrador() {
  const [tipo, setTipo] = useState();

  function handleSelectChange(e) {
    setTipo(e.target.value);
  }

  return (
    <>
      <div align="center">
        <strong>Integradores: </strong>
        <div align="center">
          <select onChange={(event) => handleSelectChange(event)}>
            <option value="">Selecione</option>
            <option value="Simplificado">STATUS DAS INTEGRAÇÕES (SIMPLIFICADO)</option>
            <option value="Completas">INFORMAÇÕES COMPLETAS</option>          
          </select>
        </div>
      </div>
      <div>
        {(() => {
          switch (tipo) {
            case "Simplificado":
              return <TableIntegradorSimplificado tipo={tipo}/>;
            case "Completas":
              return <TableIntegrador tipo={tipo}/>;
            default:
              return null;   
          }
        })()}
      </div>
    </>
  );
}

export default SelectTipoIntegrador;
