import React, { useState, useEffect, useContext } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { CountNoSisdepen, teste as testee } from "./RequisicaoAPI/Counts";
import { Button, Spinner } from "react-bootstrap";
import { Card, Table, Row, Col, Form, OverlayTrigger } from "react-bootstrap";
import { TimerContext } from "contexts/timer";
import { validateDate } from "./Validacao/regex.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EstatisticaBase = () => {
  const [bases, setBases] = useState([
    {
      base: "AC",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
      qtdPessoa: "-",
    },
    {
      base: "AL",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "AM",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "AP",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "BA",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "CE",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "DF",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "ES",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "GO",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "MA",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "MG",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "MS",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "MT",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "PA",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "PB",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "PE",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "PI",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "PR",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "RJ",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "RN",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "RO",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "RS",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "RR",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "SC",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "SE",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "SP",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
    {
      base: "TO",
      qtdInterno: "-",
      qtdAdvogado: "-",
      qtdVisita: "-",
      qtdFoto: "-",
      qtdMovExt: "-",
      qtdVisitacao: "-",
    },
  ]);

  let date = new Date();
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(new Date().toLocaleString("pt-BR"));
  const [time, setTime] = useState(90000);
  const { timer, setTimer } = useContext(TimerContext);
  const [regexDateInitialErr, setRegexDateInitialErr] = useState(false);
  const [regexDateFinalErr, setRegexDateFinalErr] = useState(false);
  const [loading, setLoading] = useState("hidden");
  const [graph, setGraph] = useState({
    labels: bases.map((b) => b.base),
    datasets: [
      {
        label: "Internos",
        data: bases.map((b) => "-"),
        backgroundColor: "#21B3AA",
      },
      {
        label: "Advogados",
        data: bases.map((b) => "-"),
        backgroundColor: "#21B3AA",
      },
      {
        label: "Visitas",
        data: bases.map((b) => "-"),
        backgroundColor: "#21B3AA",
      },
      {
        label: "Fotos",
        data: bases.map((b) => "-"),
        backgroundColor: "#21B3AA",
      },
      {
        label: "Movimentações Externas",
        data: bases.map((b) => "-"),
        backgroundColor: "#21B3AA",
      },
      {
        label: "Visitações",
        data: bases.map((b) => "-"),
        backgroundColor: "#21B3AA",
      },
    ],
  });

  function gerar_cor_hexadecimal() {
    return (
      "#" +
      parseInt(Math.random() * 0xfff)
        .toString(16)
        .padStart(3, "0")
    );
  }

  function handleButtonClick() {

    if (
      (validateDate.test(dataInicio) ||
        dataInicio == null ||
        dataInicio == "") &&
      (validateDate.test(dataFim) || dataFim == null)
    ) {
      setRegexDateInitialErr(false);
      setRegexDateFinalErr(false);
      document.getElementById("inputFinal").style.borderColor = "black";
      document.getElementById("inputInicial").style.borderColor = "black";
      bases.forEach((estado, i) => {
        updateEstado(estado, i);
      });
    } else if (
      (dataInicio == "" || dataInicio == null) &&
      validateDate.test(dataFim) == false
    ) {
      setRegexDateFinalErr(true);
      setRegexDateInitialErr(false);
      document.getElementById("inputFinal").focus();
      document.getElementById("inputFinal").style.borderColor = "red";
      document.getElementById("inputInicial").style.borderColor = "black";
    } else if (
      validateDate.test(dataInicio) == false &&
      (dataFim == "" || dataFim == null)
    ) {
      setRegexDateFinalErr(false);
      setRegexDateInitialErr(true);
      document.getElementById("inputInicial").focus();
      document.getElementById("inputFinal").style.borderColor = "black";
      document.getElementById("inputInicial").style.borderColor = "red";
    } else if (
      validateDate.test(dataInicio) == false &&
      validateDate.test(dataFim) == false
    ) {
      setRegexDateFinalErr(true);
      setRegexDateInitialErr(true);
      document.getElementById("inputInicial").focus();
      document.getElementById("inputInicial").style.borderColor = "red";
      document.getElementById("inputFinal").style.borderColor = "red";
    } else if (validateDate.test(dataInicio) == false) {
      setRegexDateInitialErr(true);
      document.getElementById("inputInicial").focus();
      document.getElementById("inputInicial").style.borderColor = "red";
    } else if (validateDate.test(dataFim) == false) {
      setRegexDateFinalErr(true);
      document.getElementById("inputFinal").focus();
      document.getElementById("inputFinal").style.borderColor = "red";
    }
  }

  async function updateEstado(estado, i) {
    setLoading("");

    const internos = await CountNoSisdepen(
      "internos",
      dataInicio,
      dataFim,
      estado.base,
      time
    );

    const advogados = await CountNoSisdepen(
      "advogados",
      dataInicio,
      dataFim,
      estado.base,
      time
    );

    const visitas = await CountNoSisdepen(
      "visitas",
      dataInicio,
      dataFim,
      estado.base,
      time
    );

    const fotos = await CountNoSisdepen(
      "fotos",
      dataInicio,
      dataFim,
      estado.base,
      time
    );

    const movExternas = await CountNoSisdepen(
      "movimentacoes/externas",
      dataInicio,
      dataFim,
      estado.base,
      time
    );

    const visitacoes = await CountNoSisdepen(
      "visitacoes",
      dataInicio,
      dataFim,
      estado.base,
      time
    );

    let basePos = bases.indexOf(estado);
    let dataInternos = graph.datasets.find((d) => d.label == "Internos").data;
    for (let i = 0; i < dataInternos.length; i++) {
      if (i == basePos) {
        dataInternos[i] = internos;
      }
    }

    let dataAdvogados = graph.datasets.find((d) => d.label == "Advogados").data;
    for (let i = 0; i < dataAdvogados.length; i++) {
      if (i == basePos) {
        dataAdvogados[i] = advogados;
      }
    }

    let dataVisitas = graph.datasets.find((d) => d.label == "Visitas").data;
    for (let i = 0; i < dataVisitas.length; i++) {
      if (i == basePos) {
        dataVisitas[i] = visitas;
      }
    }

    let dataFotos = graph.datasets.find((d) => d.label == "Fotos").data;
    for (let i = 0; i < dataFotos.length; i++) {
      if (i == basePos) {
        dataFotos[i] = fotos;
      }
    }

    let dataMovExterna = graph.datasets.find(
      (d) => d.label == "Movimentações Externas"
    ).data;
    for (let i = 0; i < dataMovExterna.length; i++) {
      if (i == basePos) {
        dataMovExterna[i] = movExternas;
      }
    }

    let dataVisitacoes = graph.datasets.find(
      (d) => d.label == "Visitações"
    ).data;
    for (let i = 0; i < dataVisitacoes.length; i++) {
      if (i == basePos) {
        dataVisitacoes[i] = visitacoes;
      }
    }

    setLoading("hidden");

    setGraph({
      labels: bases.map((b) => b.base),
      datasets: [
        {
          label: "Internos",
          data: dataInternos,
          backgroundColor: gerar_cor_hexadecimal(),
        },
        {
          label: "Advogados",
          data: dataAdvogados,
          backgroundColor: gerar_cor_hexadecimal(),
        },
        {
          label: "Visitas",
          data: dataVisitas,
          backgroundColor: gerar_cor_hexadecimal(),
        },
        {
          label: "Fotos",
          data: dataFotos,
          backgroundColor: gerar_cor_hexadecimal(),
        },
        {
          label: "Movimentações Externas",
          data: dataMovExterna,
          backgroundColor: gerar_cor_hexadecimal(),
        },
        {
          label: "Visitações",
          data: dataVisitacoes,
          backgroundColor: gerar_cor_hexadecimal(),
        },
      ],
    });

    setBases((previousState) => {
      const temp = previousState;
      temp.find((base) => base.base == estado.base).qtdInterno =
        dataInternos[basePos];
      temp.find((base) => base.base == estado.base).qtdAdvogado =
        dataAdvogados[basePos];
      temp.find((base) => base.base == estado.base).qtdVisita =
        dataVisitas[basePos];
      temp.find((base) => base.base == estado.base).qtdFoto =
        dataFotos[basePos];
      temp.find((base) => base.base == estado.base).qtdMovExt =
        dataMovExterna[basePos];
      temp.find((base) => base.base == estado.base).qtdVisitacao =
        dataVisitacoes[basePos];
      return temp;
    });
  }

  var options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 70,
      },
    },
    plugins: {
      legend: {
        position: "right",
      },
    },
  };

  useEffect(() => {
    clearInterval(timer);
    setTimer(setInterval(handleButtonClick, time));
    setDataInicio(dataInicio);
    setDataFim(dataFim);    
  }, [time, dataInicio, dataFim]);

  return (
    <>
      <Row>
        <Col>
          <Card align="center" style={{ height: 200, padding: 10 }}>
            <Row>
              <Col align="center" style={{ marginTop: 10 }}>                
                  <div>
                    <strong>Data de início dos resultados: </strong>
                  </div>
                  <input
                    id="inputInicial"
                    style={{ height: 30, borderRadius: 5 }}
                    type="text"
                    value={dataInicio}
                    onChange={(event) => {
                      setDataInicio(event.target.value);
                    }}
                    placeholder="00/00/0000 00:00:00"
                  ></input>

                  {regexDateInitialErr && (
                    <p style={{ color: "red" }}>Digite uma data válida!</p>
                  )}               
                <Row style={{ marginTop: 25 }}>
                  <Col>
                    <Button
                          size="sm"
                          variant="light"
                          active
                          onClick={() => {
                            //Passando o timestamp atual para o formato unix
                            let unixTimestamp = Math.floor(date.getTime() / 1000);
                            let newUnixTimestamp = (unixTimestamp - 600)*1000;                            
                            let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                                new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                            setDataInicio(dataAtualizada);
                            setDataFim(new Date().toLocaleString("pt-BR"));                            
                          }}
                        >
                          ÚLTIMOS 10MIN
                        </Button>                  
                        &nbsp;
                        <Button
                          size="sm"
                          variant="light"
                          active
                          onClick={() => {
                            //Passando o timestamp atual para o formato unix
                            let unixTimestamp = Math.floor(date.getTime() / 1000);
                            let newUnixTimestamp = (unixTimestamp - 1800)*1000;
                            let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                                new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                            setDataInicio(dataAtualizada); 
                            setDataFim(new Date().toLocaleString("pt-BR"))                 
                          }}
                        >
                          ÚLTIMOS 30MIN
                        </Button>
                        &nbsp;
                        <Button
                          size="sm"
                          variant="light"
                          active
                          onClick={() => {  
                              //Passando o timestamp atual para o formato unix                          
                              let unixTimestamp = Math.floor(date.getTime() / 1000);
                              let newUnixTimestamp = (unixTimestamp - 3600)*1000;
                              let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                                  new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                              setDataInicio(dataAtualizada);   
                              setDataFim(new Date().toLocaleString("pt-BR"));                    
                          }}
                        >
                          ÚLTIMA 1H
                        </Button>
                      </Col>  
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col>
                    <Button
                        size="sm"
                        variant="light"
                        active
                        onClick={() => {
                          //Passando o timestamp atual para o formato unix
                          let unixTimestamp = Math.floor(date.getTime() / 1000);
                          let newUnixTimestamp = (unixTimestamp - 43200)*1000;
                          let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                              new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                          setDataInicio(dataAtualizada);
                          setDataFim(new Date().toLocaleString("pt-BR"));                   
                        }}
                      >
                        ÚLTIMAS 12H
                      </Button>                  
                      &nbsp;
                      <Button
                        size="sm"
                        variant="light"
                        active
                        onClick={() => {
                          //Passando o timestamp atual para o formato unix
                          let unixTimestamp = Math.floor(date.getTime() / 1000);
                          let newUnixTimestamp = (unixTimestamp - 86400)*1000;
                          let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                              new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                          setDataInicio(dataAtualizada); 
                          setDataFim(new Date().toLocaleString("pt-BR"));                   
                        }}
                      >
                        ÚLTIMAS 24H
                      </Button>
                      &nbsp;
                      <Button
                        size="sm"
                        variant="light"
                        active
                        onClick={() => {
                          //Passando o timestamp atual para o formato unix
                          let unixTimestamp = Math.floor(date.getTime() / 1000);
                          let newUnixTimestamp = (unixTimestamp - 172800)*1000;
                          let dataAtualizada = new Date(newUnixTimestamp).toLocaleDateString('pt-BR') + " " +
                              new Date(newUnixTimestamp).toLocaleTimeString('pt-BR');
                          setDataInicio(dataAtualizada);  
                          setDataFim(new Date().toLocaleString("pt-BR"));                
                        }}
                      >
                        ÚLTIMAS 48H
                      </Button>
                    </Col>
                </Row>
              </Col>
              <Col align="center">
                <strong>
                  Selecione a taxa de atualização do gráfico e tabela:{" "}
                </strong>
                <div>
                  <select
                    style={{ width: 50, height: 30, borderRadius: 5 }}
                    onChange={(event) => setTime(event.target.value)}
                  >
                    <option value={90000}>90</option>
                    <option value={60000}>60</option>
                    <option value={30000}>30</option>
                  </select>
                  <strong> segundos</strong>
                </div>
                <Row style={{ marginTop: 35 }}>
                  <Col>
                    <Button
                      size="lm"
                      variant="outline-info"
                      active
                      onClick={() => {
                        handleButtonClick();
                      }}
                    >
                      CALCULAR
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col align="center" style={{ marginTop: 10 }}>
                <div>
                  <strong>Data de término dos resultados: </strong>
                </div>
                <input
                  id="inputFinal"
                  style={{ height: 30, borderRadius: 5 }}
                  type="text"
                  value={dataFim}
                  onChange={(event) => {
                    if (event.target.value != "") {
                      setDataFim(event.target.value);
                    } else {
                      setDataFim(null);
                    }
                  }}
                  placeholder="00/00/0000 00:00:00"
                ></input>
                {regexDateFinalErr && (
                  <p style={{ color: "red" }}>Digite uma data válida!</p>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div>
        <div align="center">
          <h3 className="titulo">
            GRÁFICO DE ESTATÍSTICAS POR ENTIDADE E BASE
          </h3>
        </div>
        <div>
          <Bar data={graph} height={600} options={options} />
        </div>
      </div>
      <div>
        <br></br>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td>
                <strong>Base</strong>
              </td>
              <td>
                <strong>Internos</strong>
              </td>
              <td>
                <strong>Advogados</strong>
              </td>
              <td>
                <strong>Visitas</strong>
              </td>
              <td>
                <strong>Fotos</strong>
              </td>
              <td>
                <strong>Movimentações Externas</strong>
              </td>
              <td>
                <strong>Visitações</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            {bases.map((base, i) => (
              <tr>
                <td>{base.base}</td>
                <td>
                  {loading != "hidden" ? (
                    <Spinner
                      style={{ visibility: loading }}
                      animation="border"
                    ></Spinner>
                  ) : (
                    base.qtdInterno
                  )}
                </td>
                <td>
                  {loading != "hidden" ? (
                    <Spinner
                      style={{ visibility: loading }}
                      animation="border"
                    ></Spinner>
                  ) : (
                    base.qtdAdvogado
                  )}
                </td>
                <td>
                  {loading != "hidden" ? (
                    <Spinner
                      style={{ visibility: loading }}
                      animation="border"
                    ></Spinner>
                  ) : (
                    base.qtdVisita
                  )}
                </td>
                <td>
                  {loading != "hidden" ? (
                    <Spinner
                      style={{ visibility: loading }}
                      animation="border"
                    ></Spinner>
                  ) : (
                    base.qtdFoto
                  )}
                </td>
                <td>
                  {loading != "hidden" ? (
                    <Spinner
                      style={{ visibility: loading }}
                      animation="border"
                    ></Spinner>
                  ) : (
                    base.qtdMovExt
                  )}
                </td>
                <td>
                  {loading != "hidden" ? (
                    <Spinner
                      style={{ visibility: loading }}
                      animation="border"
                    ></Spinner>
                  ) : (
                    base.qtdVisitacao
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default EstatisticaBase;
