import React, { useState } from "react";
import { useDispatch } from "react-redux";
import getBaseFromSelect from "store/Base/Base.actions";
import EstatisticaGeral from "../components/Charts/EstatisticaGeral";
import InternoNacChart from "../components/Charts/InternoNacChart";
import InternoNacMapeadosChart from "../components/Charts/InternoNacMapeadosChart.js";
import EstatisticaBase from "../components/Charts/EstatisticaBase";
import {
  Alert,
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

function SelectChart() {
  const [tipo, setTipo] = useState();
  const [modelo, setModelo] = useState();

  function handleSelectChange(e) {
    setTipo(e.target.value);
  }
  function handleModelChange(e) {
    setModelo(e.target.value);
  }

  return (
    <>
      <Row>
        <Col>
          <Card style={{ height: 75, padding: 10 }}>
            <div align="center">
              <strong>Selecione o gráfico: </strong>
              <div align="center">
                <select
                  style={{
                    height: 30,
                    borderRadius: 5,
                  }}
                  onChange={(event) => handleSelectChange(event)}
                >
                  <option value="">Selecione</option>
                  <option value="EstatisticaGeral">
                    GRÁFICO DE ESTATÍSTICAS POR ENTIDADE
                  </option>
                  <option value="EstatisticaBase">
                    GRÁFICO DE ESTATÍSTICAS POR ENTIDADE E BASE
                  </option>
                  <option value="InternoNacChart">
                    GRÁFICO DE ESTATÍSTICAS DE INTERNOS POR NACIONALIDADE
                  </option>
                  <option value="InternoNacMapeadosChart">
                    GRÁFICO DE ESTATÍSTICAS DE INTERNOS POR NACIONALIDADE(MAPEADOS)
                  </option>
                </select>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <div>
        {(() => {
          switch (tipo) {
            case "EstatisticaGeral":
              return <EstatisticaGeral />;
            case "EstatisticaBase":
              return <EstatisticaBase />;
            case "InternoNacChart":
              return <InternoNacChart />;
            case "InternoNacMapeadosChart":
              return <InternoNacMapeadosChart />;
            default:
              return null;
          }
        })()}
      </div>
    </>
  );
}

export default SelectChart;
